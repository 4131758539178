.App {
  text-align: center;
  width: calc(100% - 2rem);
  margin: 1rem;
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
  border: solid 1px #61dafb;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
}
