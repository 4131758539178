.Home {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding-bottom: 5rem;
}

.Home-image-wrapper {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 1rem;
  display: flex;
}

.Home-image {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 1rem;
}

.Home-quizbits-link {
  color: #ff8051;
  text-decoration: none;
}

.Home-quizbits-link:hover {
  color: #ab2e00;
}

